import styled from 'styled-components';

import Header from '../Header/Header';
import Socials from '../Socials/Socials';

const Overlay = ({ current }) => {
	const { title, subtitle } = current;

	return (
		<Wrapper>
			<HeaderSection>
				<Header title={title} subtitle={subtitle} />
			</HeaderSection>

			<SocialSection>
				<Socials />
			</SocialSection>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	/* Revert to allow clickable */
	visibility: initial;
`;

const HeaderSection = styled.div`
	position: absolute;
	top: var(--space-md);
	left: var(--space-md);
`;

const SocialSection = styled.div`
	position: absolute;
	bottom: var(--space-md);
	right: var(--space-md);
`;

export default Overlay;
