import styled from "styled-components";

import { data } from "../../data";

import NavLink from "./NavLink";

const NavList = () => {
	return (
		<Wrapper>
			{data.map((link, index) => {
				return (
					<NavLink key={index} href="./">
						{link.title}
					</NavLink>
				);
			})}
		</Wrapper>
	);
};

const Wrapper = styled.ul`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export default NavList;
