import { Canvas } from '@react-three/fiber'
import Experience from "./Experience"

const Gravity = () => {
    return <Canvas
        camera={ {
            fov: 45,
            near: 0.1,
            far: 200,
            position: [ - 2, 0, 6 ]
        } }
    >
        <Experience />
    </Canvas>
}

export default Gravity