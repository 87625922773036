import styled from 'styled-components';
import { useMemo } from 'react';

import Overlay from '../Overlay/Overlay';
import Cube from '../Experiences/Cube/Cube';
import NavList from '../Nav/NavList';
import Gravity from '../Experiences/Gravity/Gravity';

import { data } from '../../data';
import NavLink from '../Nav/NavLink';
const App = () => {
	const current = useMemo(() => {
		return data[0];
	}, []);

	return (
		<>
			<WrapperR3F>
				{/* <Cube /> */}
				<Gravity />
			</WrapperR3F>

			<WrapperNav>
				{/* <NavList /> */}
				{data.map((link, index) => {
					return (
						<NavLink key={index} href="./">
							{link.title}
						</NavLink>
					);
				})}
			</WrapperNav>

			<WrapperOverlay as="main">
				<Overlay current={current} />
			</WrapperOverlay>
		</>
	);
};

const WrapperOverlay = styled.div`
	position: relative;
	height: 100%;
	/* Bring to front but hide the entire div */
	z-index: 1;
	visibility: hidden;
`;
const WrapperR3F = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const WrapperNav = styled.nav`
	position: absolute;
	bottom: var(--space-md);
	left: var(--space-md);
	z-index: 1;

	display: flex;
	align-items: center;
	gap: 1rem;
`;

export default App;
