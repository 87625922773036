import styled from "styled-components"

const Socials = () =>
{
    return(
        <Wrapper>
            <Item><Link href="https://github.com/amiradeu">Github</Link></Item>
            <Item><Link href="https://twitter.com/amiradeuraseh">@AmiraDeuraseh</Link></Item>
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    display: flex;
    gap: 0.45rem;
`

const Item = styled.li``

const Link = styled.a`
    text-decoration: none;
    color: var(--color-gray-900);

    &:hover {
        text-decoration: underline;
    }
`

export default Socials