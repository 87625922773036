import ReactDOM from 'react-dom/client'

import App from './components/App/App'
import GlobalStyles from './components/GlobalStyles/GlobalStyles'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <App />
        <GlobalStyles />
    </>
)
