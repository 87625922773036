import { useThree, useFrame, extend } from '@react-three/fiber'
import { useRef } from 'react'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

extend({ OrbitControls })

export default function Experience()
{
    const cubeRef = useRef()
    const { camera, gl } = useThree()

    useFrame((state, delta) =>
    {
        cubeRef.current.rotation.y += delta
    })
    return <>
        <directionalLight position={ [ 1, 2, 3 ] } intensity={ 4.5 } />
        <ambientLight intensity={ 1.5 } />

        <orbitControls args={ [ camera, gl.domElement ] } />
        <mesh ref={ cubeRef } scale={ 1.5 }>
            <boxGeometry />
            <meshStandardMaterial color="mediumpurple" />
        </mesh>
        <mesh position-y={ -1 } rotation-x={ - Math.PI * 0.5 } scale={ 10 }>
            <planeGeometry />
            <meshStandardMaterial color="greenyellow" />
        </mesh>
    </>
}