import styled from "styled-components"

const Header = ({ title, subtitle }) =>
{
    return(
        <Wrapper>
            <Title>{ title }</Title>
            <Subtitle>{ subtitle }</Subtitle>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: baseline;
`

const Title = styled.h1`
    font-size: 1.4rem;
    font-weight: var(--font-weight-normal);

    &:after {
        content: ''
    }
`

const Subtitle = styled.a`
    font-style: italic;
`

export default Header