export const data = [
	{
		title: 'Cube',
		subtitle: 'inspired by Cube',
		path: 'Cube',
	},
	{
		title: 'Gravity',
		subtitle: 'inspired by ABC',
		path: 'Gravity',
	},
	{
		title: 'Hyper Dispersion',
		subtitle: 'inspired by DEF',
		path: 'HyperDispersion',
	},
];
