import styled from "styled-components"

const NavLink = ({ href, children, delegated }) =>
{
  return(
    <LinkLi { ...delegated }>
      <Link href={ href }>
        { children }
      </Link>
    </LinkLi>
  )
}

const LinkLi = styled.li``

const Link = styled.a`
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  font-weight: var(--font-bold);
  color: var(--color-gray-500);

  cursor: pointer;

  &.active {
    color: var(--color-gray-900);
  }

  &:hover {
    color: var(--color-gray-900);
  }
`

export default NavLink